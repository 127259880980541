import React from 'react';
import styles from './TwoImageSection.module.scss';

function TwoImageSection(props) {
  const data = props.data || props;
  
  const imageCount = (data?.image_left ? 1 : 0) + (data?.image_right ? 1 : 0);
  const videoCount = (data?.youtube_iframe_left ? 1 : 0) + (data?.youtube_iframe_right ? 1 : 0);
  
  const hasOneImageTwoVideos = imageCount === 1 && videoCount === 2;
  const hasTwoImagesOneVideo = imageCount === 2 && videoCount === 1;
  const hasOneImageOneVideo = imageCount === 1 && videoCount === 1;

  return (
    <section style={{ backgroundColor: data?.background_color || '#ffffff' }}>
      <div className="container">
        {(data?.image_left || data?.image_right || data?.youtube_iframe_left || data?.youtube_iframe_right) && (
          <div className={`${styles.twoImageSection} row`}>
            {imageCount === 1 && videoCount === 0 && (
              <div className="col-md-12">
                <img src={data?.image_left || data?.image_right} className={styles.media} alt="" />
              </div>
            )}
            
            {imageCount === 0 && videoCount === 1 && (
              <div className="col-md-12">
                <div className={styles.VideoWrapper} dangerouslySetInnerHTML={{ __html: data?.youtube_iframe_left || data?.youtube_iframe_right }} />
              </div>
            )}
            
            {imageCount === 2 && videoCount === 0 && (
              <>
                <div className="col-md-6">
                  <img src={data?.image_left} className={styles.media} alt="" />
                </div>
                <div className={`${styles.bottom} col-md-6`}>
                  <img src={data?.image_right} className={styles.media} alt="" />
                </div>
              </>
            )}
            
            {imageCount === 0 && videoCount === 2 && (
              <>
                <div className="col-md-6">
                  <div className={styles.VideoWrapper} dangerouslySetInnerHTML={{ __html: data.youtube_iframe_left }} />
                </div>
                <div className={`${styles.bottom} col-md-6`}>
                  <div className={styles.VideoWrapper} dangerouslySetInnerHTML={{ __html: data.youtube_iframe_right }} />
                </div>
              </>
            )}
            
            {hasOneImageOneVideo && (
              <>
                {data?.image_left && (data?.youtube_iframe_left || data?.youtube_iframe_right) && (
                  <>
                    <div className="col-md-6">
                      <img src={data?.image_left} className={styles.media} alt="" />
                    </div>
                    <div className={`${styles.bottom} col-md-6`}>
                      <div className={styles.VideoWrapper} dangerouslySetInnerHTML={{ __html: data?.youtube_iframe_left || data?.youtube_iframe_right }} />
                    </div>
                  </>
                )}
                
                {data?.image_right && (data?.youtube_iframe_left || data?.youtube_iframe_right) && !data?.image_left && (
                  <>
                    <div className="col-md-6">
                      <div className={styles.VideoWrapper} dangerouslySetInnerHTML={{ __html: data?.youtube_iframe_left || data?.youtube_iframe_right }} />
                    </div>
                    <div className={`${styles.bottom} col-md-6`}>
                      <img src={data?.image_right} className={styles.media} alt="" />
                    </div>
                  </>
                )}
              </>
            )}
            
            {hasOneImageTwoVideos && (
              <>
                <div className="col-md-12">
                  <img src={data?.image_left || data?.image_right} className={styles.media} alt="" />
                </div>
                <div className="col-md-6">
                  <div className={styles.VideoWrapper} dangerouslySetInnerHTML={{ __html: data.youtube_iframe_left }} />
                </div>
                <div className={`${styles.bottom} col-md-6`}>
                  <div className={styles.VideoWrapper} dangerouslySetInnerHTML={{ __html: data.youtube_iframe_right }} />
                </div>
              </>
            )}
            
            {hasTwoImagesOneVideo && (
              <>
                <div className="col-md-6">
                  <img src={data?.image_left} className={styles.media} alt="" />
                </div>
                <div className={`${styles.bottom} col-md-6`}>
                  <img src={data?.image_right} className={styles.media} alt="" />
                </div>
                <div className="col-md-12">
                  <div className={styles.VideoWrapper} dangerouslySetInnerHTML={{ __html: data?.youtube_iframe_left || data?.youtube_iframe_right }} />
                </div>
              </>
            )}
            
            {imageCount === 2 && videoCount === 2 && (
              <>
                <div className="col-md-6">
                  <img src={data?.image_left} className={styles.media} alt="" />
                </div>
                <div className={`${styles.bottom} col-md-6`}>
                  <img src={data?.image_right} className={styles.media} alt="" />
                </div>
                <div className="col-md-6">
                  <div className={styles.VideoWrapper} dangerouslySetInnerHTML={{ __html: data.youtube_iframe_left }} />
                </div>
                <div className={`${styles.bottom} col-md-6`}>
                  <div className={styles.VideoWrapper} dangerouslySetInnerHTML={{ __html: data.youtube_iframe_right }} />
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </section>
  );
}

export default TwoImageSection;